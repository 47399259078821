import styled from 'styled-components';
import { SPINNER_SIZES } from './';
const getSize = ({ customSize, size = 'medium' }) => {
    return customSize ? customSize : SPINNER_SIZES[size];
};
// const getColor = (color, theme) => {
//   return color && theme.palette?.[color]?.[300];
// };
/* color: ${({ color, theme }) => getColor(color, theme)}; */
const StyledSpinner = styled.div `
  max-width: ${(props) => getSize(props)} !important;
  max-height: ${(props) => getSize(props)} !important;
`;
export default StyledSpinner;
