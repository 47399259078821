import { combineReducers } from 'redux';

import { currUser } from './reducers/users';
import { dataProps, currDataSheet, firebaseReadCount } from './reducers/data';
import {
  activeCell,
  inputValue,
  metaKey,
  copied,
  tableErrors,
} from './reducers/dataSheet';

export default combineReducers({
  currUser,
  dataProps,
  currDataSheet,
  firebaseReadCount,

  // dataSheet reducers
  activeCell,
  inputValue,
  metaKey,
  copied,
  tableErrors,
});
