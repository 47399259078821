import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Spinner } from '@datarips/components';
import { getEntityName } from '@datarips/utils';

import actions from 'services/actions';
import SheetCard, { SHEET_STATES } from './components/SheetCard';

const rightImgPath = '/img/graphics/login.png';

function getFormattedSheet(sheet, currUser) {
  const { id, month, isSent, entity } = sheet;
  return {
    id,
    month,
    status: isSent ? SHEET_STATES.Sent : SHEET_STATES.Editing,
    isClickable: true,
    ...(entity && {
      entity: getEntityName(entity, currUser),
    }),
  };
}

function Dashboard(props) {
  const { currUser } = props;
  const [monthCardLoader, setMonthCardLoader] = useState(null);

  useEffect(() => {
    props.clearCurrDataSheet();
    props.fetchCurrUserSheets();
  }, []);

  const getMissingMonthsByYear = (year) => {
    const activeMonths = (currUser.sheets[year] || []).map(
      (item) => item.month,
    );
    const currDate = {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    };
    const monthLimits = {
      min: activeMonths.at(-1) || 0, // oldest active month as minimum
      max: currDate.year === parseInt(year) ? currDate.month : 11, // current month as maximum
    };
    const allMonthsUntilNow = Array.from(
      Array(monthLimits.max - monthLimits.min + 1).keys(),
      (item) => item + monthLimits.min,
    );
    // remove activeMonths from allMonthsUntilNow to get missingMonths
    return allMonthsUntilNow.filter((month) => !activeMonths.includes(month));
  };

  const getAllMonthsByYear = (year) => {
    const missingMonths = getMissingMonthsByYear(year).map((month) => ({
      id: 'missing-' + month,
      month,
      status: SHEET_STATES.NotCreated,
      isLoading: monthCardLoader === month,
      onClick: () => onMissingMonthClick(month, year),
    }));
    const activeMonths = (currUser.sheets[year] || []).map((item) =>
      getFormattedSheet(item, currUser),
    );
    return [...missingMonths, ...activeMonths].sort(
      (a, b) => b.month - a.month,
    );
  };

  const onMissingMonthClick = async (month, year) => {
    setMonthCardLoader(month);
    const newDatasheet = await props.createDatasheet(month, year, currUser.uid);
    await props.createUserSheet(newDatasheet.id, month, year, currUser.uid);
    props.fetchCurrUserSheets().then(() => setMonthCardLoader(null));
  };

  const getYearsToDisplay = () => {
    const currYear = new Date().getFullYear();
    let years = Object.keys(currUser.sheets);
    // if years does not include current year, it needs to be added
    if (!years.find((value) => parseInt(value) === currYear)) {
      years.push(currYear.toString());
    }
    // last year should be at the beginning
    return years.sort((a, b) => b - a);
  };

  return (
    <Content>
      <img className="bg-img" src={rightImgPath} alt="background" />
      <div className="header">
        <h1 className="title">Mis planillas</h1>
        <a href='https://forms.gle/YTfCXYMuke4aihta7'>
          <Button>Inscribirme para nuevos RIPS json</Button>
        </a>
      </div>
      {!currUser ? (
        <Spinner type={2} />
      ) : currUser.sheets ? (
        getYearsToDisplay().map((year) => (
          <section key={`${year}-sheets`}>
            <h4 className="subtitle">Año {year}</h4>
            <div className="grid">
              {getAllMonthsByYear(year).map((item) => (
                <SheetCard key={item.id} {...item} />
              ))}
            </div>
          </section>
        ))
      ) : (
        <center className="message">
          <h3>¡Ups! No tienes planillas activas</h3>
          <p>
            Por favor, pídele al administrador que active la planilla de este
            mes para que comiences a usar DataRIPS - Planillas
          </p>
        </center>
      )}
    </Content>
  );
}

const Content = styled.div`
  padding: 2em 7%;
  background-color: ${(props) => props.theme.palette.neutral[300]};
  min-height: calc(100vh - 8em);
  max-width: 1270px;
  margin: 0 auto;
  box-sizing: content-box;

  .bg-img {
    position: absolute;
    right: 0;
    height: calc(100vh - 8em);
    opacity: 0.1;
    z-index: 0; // fix to avoid bg-img being over the content
  }
  &>.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    margin-bottom: 1em;
  }
  .subtitle {
    color: ${(props) => props.theme.palette.neutral[700]};
  }
  .message {
    max-width: 20em;
    margin: 0 auto;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px 62px;
    & > * {
      z-index: 1; // fix to avoid bg-img being over the content
      /* max-width: 280px; */
    }
  }
`;

const mapStateToProps = ({ currUser }) => ({ currUser });
export default connect(mapStateToProps, actions)(Dashboard);
