import React from 'react';
import styled from 'styled-components';
import { Button } from '../atoms';
function Menu(props) {
    const { options, clickHandler } = props;
    const handleClick = (action) => {
        clickHandler();
        action();
    };
    return (React.createElement("div", { ...props }, options.map((item, index) => (React.createElement(Button, { key: 'menu-option-' + index, isOutlined: !item.primary, onClick: () => handleClick(item.action) }, item.text)))));
}
const StyledMenu = styled(Menu) `
  ${(props) => `
    display: ${props.isActive ? 'inline-flex' : 'none'};
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(17, 51, 70, 0.1);
    border-radius: 0px 0px 4px 4px;
    font-family: Nunito;
    min-width: 12em;

    & > * {
      border: none;
      text-decoration: none;

      &:not(:last-child) {
        border-bottom: solid 1.5px ${props.theme.palette.neutral[400]};
      }
    }
  `}
`;
export default StyledMenu;
