module.exports = {
  anesthesiology: 'Anestesiología',
  dermatology: 'Dermatología',
  physiotherapy: 'Fisioterapia',
  imaging: 'Imagenología',
  odontology: 'Odontología',
  optometry: 'Optometría',
  orthopedics: 'Ortopedia',
  psychology: 'Psicología',
};
