import { firebase } from '@firebase/app';
import { FirebaseConfig } from './config';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

// Initialize firebase app
firebase.initializeApp(FirebaseConfig);

// Start analytics reference
firebase.analytics();

// Use emulator when running app in localhost
const db = firebase.firestore();
if (window.location.hostname === 'localhost') {
  db.useEmulator('localhost', 8000);
}

// Export database references
export const usersRef = db.collection('users');
export const dataSheetsRef = db.collection('dataSheets');
export const dataPropsRef = db.collection('dataProps');
export const sentSheetsRef = db.collection('sentDataSheets');

// Export auth reference
export const auth = firebase.auth();
if (window.location.hostname === 'localhost') {
  auth.useEmulator('http://localhost:9099/');
}

// Export other references
export const fieldValue = firebase.firestore.FieldValue;
export const fieldPath = firebase.firestore.FieldPath;
