import React from 'react';
import Spinner1 from './Spinner1';
import Spinner2 from './Spinner2';
import Spinner3 from './Spinner3';
import Spinner4 from './Spinner4';
export const SPINNER_SIZES = {
    small: '2em',
    medium: '4em',
    large: '8em',
    huge: '16em',
};
function Spinner({ 
// color = 'primary',
type, size = 'medium', ...props }) {
    // const allProps = { color, type, size, ...props };
    const allProps = { type, size, ...props };
    switch (type) {
        case 1:
            return React.createElement(Spinner1, { ...allProps });
        case 2:
            return React.createElement(Spinner2, { ...allProps });
        case 3:
            return React.createElement(Spinner3, { ...allProps });
        case 4:
            return React.createElement(Spinner4, { ...allProps });
        default:
            return React.createElement(Spinner1, { ...allProps });
    }
}
export default Spinner;
