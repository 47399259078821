import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { neutral } from '../utils';
import Icon from './Icon';
export const MODAL_WINDOW_SIZES = ['large', 'default'];
export default function Modal({ isOpened, closeIcon, onClose, onBackdropClick, windowSize = 'default', children, container, }) {
    if (isOpened) {
        return ReactDOM.createPortal(React.createElement(React.Fragment, null,
            React.createElement(Shadow, { onClick: onBackdropClick }),
            React.createElement(StyledModal, { role: "dialog", closeIcon: closeIcon, windowSize: windowSize },
                closeIcon ? (React.createElement(CloseIcon, { onClick: onClose },
                    React.createElement(Icon, { icon: "x", size: 22, weight: "bold" }))) : null,
                children)), container || document.body);
    }
    else {
        return null;
    }
}
const StyledModal = styled.div `
  min-height: 5em;
  max-height: 90vh;
  overflow: auto;
  position: absolute;
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 46px 26px;
  padding-top: ${(props) => (props.closeIcon ? '62px' : '46px')};
  width: calc(100% - 3em);
  background: ${(props) => props.theme.backgroundColor};
  border-radius: 10px;
  box-shadow: 4px 5px 16px rgba(0, 0, 0, 0.2);

  /* conditional styles */
  ${(props) => props.windowSize === 'large'
    ? css `
          max-width: ${props.theme.breakpoints.lg};
        `
    : css `
          max-width: ${(props) => props.theme.breakpoints.sm};
        `}
`;
const Shadow = styled.div `
  background: black;
  opacity: 0.2;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;
const CloseIcon = styled.button `
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  height: 62px;
  background: none;
  border: none;
  color: ${neutral[600]};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
