import { auth, usersRef } from 'services/firebase';
import { FETCH_CURR_USER, FETCH_CURR_USER_SHEETS } from 'services/actionTypes';

// ---- HELPERS ---- //

export function getFormattedSheet(doc) {
  const sheet = doc.data();
  // set some attributes for legacy sheets
  sheet.isSent = sheet.isSent || sheet.sent;
  delete sheet.sent;
  return {
    ...sheet,
    id: doc.id,
  };
}

// --------------------------------- //
// ------------ AUTH --------------- //
// --------------------------------- //

export const login =
  ({ email, password }) =>
  async () => {
    return auth.signInWithEmailAndPassword(email, password);
  };

export const logout = () => async () => {
  return auth.signOut();
};

export const sendPasswordLink =
  ({ email }) =>
  async () => {
    return auth.sendPasswordResetEmail(email);
  };

// ----------------------------------- //
// ------------ CREATE --------------- //
// ----------------------------------- //

export const createUserSheet =
  (sheetId, month, year, uid, entity) => async () => {
    const newSheet = usersRef.doc(uid).collection('sheets').doc(sheetId);
    return newSheet.set({
      month: parseInt(month),
      year: parseInt(year),
      isSent: false,
      ...(entity && { entity }),
    });
  };

// ---------------------------------- //
// ------------ FETCH --------------- //
// ---------------------------------- //

export const fetchCurrUser = (uid, basicData) => async (dispatch) => {
  usersRef
    .doc(uid)
    .get()
    .then(async (doc) => {
      let currUser = { uid, ...basicData, ...doc.data() };
      const sheets = await getUserSheetsGroupedByYear(currUser.uid);
      currUser = { ...currUser, sheets };
      dispatch({ type: FETCH_CURR_USER, currUser });
    });
};

export const fetchCurrUserSheets = () => async (dispatch, getState) => {
  const { currUser } = getState();
  if (currUser) {
    const sheets = await getUserSheetsGroupedByYear(currUser.uid);
    dispatch({ type: FETCH_CURR_USER_SHEETS, sheets });
  }
};

async function getUserSheetsGroupedByYear(uid) {
  const sheetsRef = usersRef
    .doc(uid)
    .collection('sheets')
    .orderBy('year', 'desc')
    .orderBy('month', 'desc')
    .limit(10);
  const snapshot = await sheetsRef.get();
  const sheets = snapshot.docs.reduce((acc, doc) => {
    const item = getFormattedSheet(doc);
    if (!acc[item.year]) {
      acc[item.year] = [item];
    } else {
      acc[item.year].push(item);
    }
    return acc;
  }, {});

  return sheets;
}
