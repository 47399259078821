module.exports = [
	{ key: 'invoice', text: 'FACTURA' },
	{ key: 'day', text: 'FECHA' },
	{ key: 'first_lastname', text: '1 APELLIDO' },
	{ key: 'second_lastname', text: '2 APELLIDO' },
	{ key: 'first_name', text: '1 NOMBRE' },
	{ key: 'second_name', text: '2 NOMBRE' },
	{ key: 'municipality', text: 'LOCAL' },
	{ key: 'id_type', text: 'ID' },
	{ key: 'id_number', text: 'NO. ID' },
	{ key: 'age', text: 'EDAD' },
	{ key: 'sex', text: 'SEXO' },
	{ key: 'diagnosis', text: 'DIAGN' },
	{ key: 'procedure', text: 'PROCED' },
	{ key: 'value', text: 'VALOR' },
];
