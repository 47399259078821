import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'services/actions';
import styled from 'styled-components';
import { Spinner } from '@datarips/components';
import Sheet from './components/Sheet';

function Editor({ currDataSheet, dataProps, ...props }) {
  useEffect(() => {
    props.fetchCurrDataSheetById(props.match.params.dataSheetId);
  }, [props.match, props.fetchCurrDataSheetById]);

  return (
    <StyledEditor>
      {!(dataProps && dataProps.length > 0 && currDataSheet) ? (
        <Spinner className="spinner" type={2} color="primary" />
      ) : (
        <Sheet
          data={currDataSheet.data}
          headers={dataProps}
          editable={!currDataSheet.isSent}
        />
      )}
    </StyledEditor>
  );
}

const StyledEditor = styled.div`
  /* margin top for the sticky navbar */
  margin-top: 60px;
  .spinner {
    margin-top: 20%;
  }
`;

const mapStateToProps = ({ currUser, currDataSheet, dataProps }) => ({
  currUser,
  currDataSheet,
  dataProps,
});
export default connect(mapStateToProps, actions)(Editor);
