import { createGlobalStyle, css } from 'styled-components';
export const GlobalStyle = createGlobalStyle `
  // -------- NORMALIZING --------- //
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  // -------- CUSTOM STYLES --------- //

  ${({ theme }) => css `
    html {
      font-size: ${theme.typeScale.desktop.basePx};
      box-sizing: border-box;

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      *:disabled {
        cursor: not-allowed;
      }

      body {
        margin: 0;
        font-family: ${theme.fonts.body};
        color: ${theme.textColor.main};
        background-color: ${theme.backgroundColor};
        -webkit-font-smoothing: antialiased;
      }

      main {
        max-width: 90%;
        margin: 0 auto;
      }

      // ---------- TITLES ----------- //
      h1,
      h2,
      h3,
      h4,
      h5,
      b {
        font-family: ${theme.fonts.titles};
        font-weight: bold;
        color: ${theme.titlesColor.main};
      }
      h1 {
        font-size: ${theme.typeScale.desktop.lg};
      }
      h2 {
        font-size: ${theme.typeScale.desktop.md};
      }
      h3 {
        font-size: ${theme.typeScale.desktop.sm};
      }
      h4 {
        font-size: ${theme.typeScale.desktop.xs};
      }
      h5 {
        font-size: ${theme.typeScale.desktop.paragraph};
      }

      // ---------- LINKS ----------- //
      a {
        color: ${theme.primary.main};
        font-weight: 600;
        text-decoration: underline;
      }

      // ---------- SMALL ----------- //
      small {
        font-size: ${theme.typeScale.desktop.helperText};
      }

      // --------- TOOLTIPS ---------- //
      [data-tooltip] {
        &:hover:after,
        &:hover:before {
          display: inline;
        }

        // POSITIONS
        &[data-tooltip-pos='top'] {
          &:after {
            transform: translate(-50%, calc(-100% - 0.5rem));
          }
          &:before {
            border-color: ${theme.tooltip.backgroundColor.main} transparent
              transparent transparent;
            transform: translate(100%, -0.5rem);
          }
        }
        &[data-tooltip-pos='bottom'] {
          &:after {
            transform: translate(-50%, 2rem);
          }
          &:before {
            border-color: transparent transparent
              ${theme.tooltip.backgroundColor.main} transparent;
            transform: translate(100%, calc(100% + 0.5em));
          }
        }
        &[data-tooltip-pos='left'] {
          &:after {
            transform: translate(-100%, 0);
          }
          &:before {
            border-color: transparent transparent transparent
              ${theme.tooltip.backgroundColor.main};
            left: 0;
          }
        }
        &[data-tooltip-pos='right'] {
          &:after {
            transform: translate(100%, 0);
          }
          &:before {
            border-color: transparent ${theme.tooltip.backgroundColor.main}
              transparent transparent;
            right: 0;
          }
        }

        // FADE ANIMATION
        &:after,
        &:before {
          display: none;
          z-index: 3;
          position: absolute;

          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }

        // TOOLTIP STYLE AND ARROW
        &:after {
          content: attr(data-tooltip);
          white-space: pre-wrap;

          font-size: 0.85rem;
          line-height: 1.4em;
          max-width: 200px;
          border-radius: 3px;
          padding: 0.4em 0.7em;
          background-color: ${theme.tooltip.backgroundColor.main};
          color: ${theme.tooltip.backgroundColor.contrast};
        }
        &:before {
          content: '';
          border-width: 0.4rem;
          border-style: solid;
        }
      }
    }
  `}

  // ------------ FONTS ------------- //

  // Nunito for the titles
  @font-face {
    font-family: Nunito;
    src: local(Nunito-Light), url(/fonts/Nunito-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: Nunito;
    src: local(Nunito-Regular),
      url(/fonts/Nunito-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: Nunito;
    src: local(Nunito-SemiBold),
      url(/fonts/Nunito-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: Nunito;
    src: local(Nunito-Bold), url(/fonts/Nunito-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: Nunito;
    src: local(Nunito-ExtraBold),
      url(/fonts/Nunito-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
  }

  // NunitoSans for the texts
  @font-face {
    font-family: NunitoSans;
    src: local(NunitoSans-Light),
      url(/fonts/NunitoSans-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NunitoSans;
    src: local(NunitoSans-Regular),
      url(/fonts/NunitoSans-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NunitoSans;
    src: local(NunitoSans-SemiBold),
      url(/fonts/NunitoSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NunitoSans;
    src: local(NunitoSans-Bold),
      url(/fonts/NunitoSans-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NunitoSans;
    src: local(NunitoSans-ExtraBold),
      url(/fonts/NunitoSans-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
  }
`;
