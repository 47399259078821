import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { connect } from 'react-redux';
import actions from 'services/actions';
import { auth } from 'services/firebase';

// Pages
import Loader from 'shared/components/pages/Loader';
import Home from 'shared/components/pages/Home';
import Login from 'modules/login/Login';
import Editor from 'modules/editor/Editor';
import Dash from 'modules/dashboard/Dashboard';

// Components
import CustomNavbar from 'shared/components/organisms/CustomNavbar/CustomNavbar';

function App(props) {
  const [userID, setUserID] = useState(undefined);

  auth.onAuthStateChanged((user) => {
    if (user) {
      const { uid, displayName, email, photoURL } = user;
      setUserID(uid);
      props.fetchCurrUser(uid, { displayName, email, photoURL });
      props.fetchDataProps();
    } else {
      setUserID(null);
    }
  });

  const PrivateRoute = (routeProps) => {
    return userID ? <Route {...routeProps} /> : <Redirect to={routes.LOGIN} />;
  };

  const PublicRoute = (routeProps) => {
    return userID ? (
      <Redirect to={routes.DASHBOARD} />
    ) : (
      <Route {...routeProps} />
    );
  };

  return userID === undefined ? (
    <Loader />
  ) : (
    // <Button className='button' color='crazy'>
    //   Contáctanos ahora
    // </Button>
    <BrowserRouter>
      <Switch>
        <Route render={(props) => <CustomNavbar {...props} />} />
      </Switch>

      <Switch>
        <PublicRoute exact path={routes.HOME} component={Home} />
        <PublicRoute exact path={routes.LOGIN} component={Login} />
        <PrivateRoute
          path={`${routes.EDITOR}/:dataSheetId`}
          component={Editor}
        />
        <PrivateRoute exact path={routes.DASHBOARD} component={Dash} />
      </Switch>
    </BrowserRouter>
  );
}

export default connect(null, actions)(App);
