import {
  dataPropsRef,
  dataSheetsRef,
  sentSheetsRef,
  usersRef,
} from 'services/firebase';
import {
  FETCH_DATAPROPS,
  FETCH_CURR_DATASHEET,
  ADD_FIREBASE_READ,
} from 'services/actionTypes';
import { setActiveCell } from './dataSheet';
import firebase from 'firebase/app';

// ---- HELPERS ---- //

function getFormattedSheet(doc) {
  const sheet = doc.data();
  // set some attributes for legacy sheets
  sheet.isSent = sheet.isSent || sheet.sent;
  delete sheet.sent;
  return {
    ...sheet,
    id: doc.id,
  };
}

// ----------------------------------- //
// ------------ CREATE --------------- //
// ----------------------------------- //

export const createDatasheet = (month, year, uid, entity) => async () => {
  return dataSheetsRef.add({
    author: usersRef.doc(uid),
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    data: new Array(10).fill().map(() => ({})),
    isSent: false,
    month: parseInt(month),
    year: parseInt(year),
    ...(entity && { entity }),
  });
};

// ---------------------------------- //
// ------------ FETCH --------------- //
// ---------------------------------- //

// Fetch data properties (for the table headers)
export const fetchDataProps = () => async (dispatch) => {
  const dataProps = [];

  dataPropsRef.get().then((snap) => {
    snap.forEach((doc) => dataProps.push({ ...doc.data(), key: doc.id }));
    dispatch({ type: FETCH_DATAPROPS, dataProps: dataProps });
  });
};

// Fetch current dataSheet by sheetId
export const fetchCurrDataSheetById = (sheetId) => async (dispatch) => {
  dataSheetsRef.doc(sheetId).onSnapshot((doc) => {
    dispatch({ type: ADD_FIREBASE_READ });
    dispatch({
      type: FETCH_CURR_DATASHEET,
      currDataSheet: getFormattedSheet(doc),
    });
  });
};

// ----------------------------------- //
// ------------ MODIFY --------------- //
// ----------------------------------- //

// Clear current dataSheet variable from state
export const clearCurrDataSheet = () => async (dispatch) => {
  dispatch({ type: FETCH_CURR_DATASHEET, currDataSheet: null });
};

// Send current dataSheet
export const sendDataSheet = () => async (dispatch, getState) => {
  const { currDataSheet, currUser } = getState();
  const { uid, name, email } = currUser;

  return sentSheetsRef
    .doc(currDataSheet.id)
    .set({
      author: { uid, name, email },
      createdAt: new Date(),
      month: currDataSheet.month,
    })
    .then(() => dispatch(changeCurrSheet({ action: 'SEND' })));
};

// Change currDataSheet (add and delete rows, and change cells)
export const changeCurrSheet =
  ({ action, value }) =>
  (dispatch, getState) => {
    const { activeCell, currDataSheet, dataProps, currUser } = getState();
    const [row, col] = activeCell.id ? activeCell.id : [];
    const sheetId = currDataSheet.id;
    const propsKeys = dataProps.map((item) => item.key);

    return dataSheetsRef
      .doc(sheetId)
      .get()
      .then((doc) => {
        const newSheet = { ...doc.data() };

        switch (action) {
          case 'ADD_LAST_ROW':
            newSheet.data.push({});
            break;

          case 'DELETE_LAST_ROW':
            newSheet.data.pop();
            if (newSheet.data.length === 0) newSheet.data.push({});
            break;

          case 'CHANGE_CELL':
            if (value.trim() !== '') newSheet.data[row][col] = value;
            break;

          case 'REMOVE_EMPTY_ROWS':
            newSheet.data.forEach((row) => {
              for (const item in row) {
                if (row[item] === null) {
                  delete row[item];
                }
              }
            });
            newSheet.data = newSheet.data.filter(
              (row) => Object.keys(row).length !== 0,
            );
            // set activeCell pos to first row & first col
            dispatch(
              setActiveCell([
                '0',
                dataProps.find((item) => item.index === 0).key,
              ]),
            );
            break;

          case 'CHANGE_SELECTION': {
            const firstProp = propsKeys.indexOf(col);
            const lastProp = value
              ? firstProp + value[0].length - 1
              : propsKeys.indexOf(activeCell.lastId[1]);
            const props =
              firstProp < lastProp
                ? propsKeys.slice(firstProp, lastProp + 1)
                : propsKeys.slice(lastProp, firstProp + 1);
            const length = value
              ? value.length
              : Math.abs(activeCell.lastId[0] - row) + 1;
            const firstRow =
              row < activeCell.lastId[0] ? row : activeCell.lastId[0];

            for (let i = 0; i < length; i++) {
              // if there are no enough rows
              if (!newSheet.data[i + firstRow]) newSheet.data.push({});
              props.forEach(
                (prop, index) =>
                  (newSheet.data[i + firstRow][prop] =
                    value && value[i][index] ? value[i][index] : null),
              );
            }
            break;
          }

          case 'SEND':
            // user sheet data (for dash) must be updated too
            usersRef
              .doc(currUser.uid)
              .collection('sheets')
              .doc(sheetId)
              .update({
                isSent: true,
              });
            newSheet.isSent = true;
            break;

          default:
            console.log(
              'Bad choice, please select a valid "action" to changeCurrSheet.',
            );
        }

        return dataSheetsRef
          .doc(sheetId)
          .update({ ...newSheet, updatedAt: new Date() });
      });
  };
