export const neutral = {
    white: '#ffffff',
    200: '#FBFBFB',
    300: '#F9F9F9',
    400: '#EFEFEF',
    500: '#E4E4E4',
    600: '#C8C8C8',
    700: '#868686',
    800: '#666666',
    900: '#323232',
    black: '#212121',
};
export const emerald = {
    100: '#F7FBFC',
    200: '#EAF6F4',
    300: '#2DA894',
    400: '#11524E',
};
export const cyan = {
    100: '#FBFEFE',
    200: '#E8F7FA',
    300: '#2BAECE',
};
export const green = {
    200: '#E2FFDF',
    300: '#209652',
};
export const yellow = {
    200: '#FFF9C7',
    300: '#C1940B',
};
export const blue = {
    100: '#F9FBFE',
    200: '#E8F0FD',
    300: '#2995E3',
};
export const red = {
    200: '#FFE9E9',
    300: '#EB5757',
};
export const crazy = {
    300: 'linear-gradient(270deg, #38C654 2.58%, #2BAECF 97.42%)',
};
const palettes = { neutral, emerald, cyan, green, yellow, blue, red, crazy };
export default palettes;
