import styled from 'styled-components';
import routes from 'shared/constants/routes';
import { SimpleNavbar } from '@datarips/components';

export default styled(SimpleNavbar)`
  height: 60px;
  ${(props) => (props.path === routes.LOGIN ? 'background: transparent;' : '')}

  & > * {
    display: flex;
    align-items: center;
  }

  .navbar-left {
    .text {
      margin-left: 1em;
      h3 {
        margin-top: 0;
        margin-bottom: -0.1em;
        color: ${(props) => props.theme.textColor};
      }
      small {
        color: ${(props) => props.theme.palette.neutral[700]};
      }
    }
    a {
      text-decoration: none;
      i {
        color: ${(props) => props.theme.palette.neutral[600]};
        font-size: 1.7em;
        margin-right: 0.5em;
      }
    }
  }

  .navbar-right {
    .buttons {
      margin-right: 5em;
      display: float;
      button:not(:last-child) {
        margin-right: 1.5em;
      }
    }
  }
`;
