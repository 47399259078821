// USERS
export const FETCH_CURR_USER = 'FETCH_CURR_USER';
export const FETCH_CURR_USER_SHEETS = 'FETCH_CURR_USER_SHEETS';

// DATA
export const FETCH_DATAPROPS = 'FETCH_DATAPROPS';
export const FETCH_CURR_DATASHEET = 'FETCH_CURR_DATASHEET';

// DATASHEET
export const TOGGLE_EDIT = 'TOGGLE_EDIT';
export const TOGGLE_FOCUS = 'TOGGLE_FOCUS';

export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const SET_ACTIVE_CELL = 'SET_ACTIVE_CELL';
export const DELETE_ACTIVE_CELL = 'DELETE_ACTIVE_CELL';
export const MOVE_ACTIVE_CELL = 'MOVE_ACTIVE_CELL';

export const SET_SELECTION = 'SET_SELECTION';
export const MOVE_SELECTION = 'MOVE_SELECTION';

export const SET_META_KEY = 'SET_META_KEY';
export const COPY_CONTENT = 'COPY_CONTENT';
export const CUT_CONTENT = 'CUT_CONTENT';
export const MOVE_CONTENT = 'MOVE_CONTENT';

export const CHANGE_ERRORS_LIST = 'CHANGE_ERRORS_LIST';
export const CHECK_REQUIRED = 'CHECK_REQUIRED';
export const CLEAR_TABLE_ERRORS = 'CLEAR_TABLE_ERRORS';
export const ADD_FIREBASE_READ = 'ADD_FIREBASE_READ';
