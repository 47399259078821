import React, { useEffect } from 'react';
import { scrollingKeys } from 'shared/constants/keyCodes';
import CellInput from './CellInput';

const ActiveCell = (props) => {
  const { activeCell, inputValue, headers, onDoubleClick, onInputChange } =
    props;

  // prevent scrolling with arrows
  useEffect(() => {
    window.addEventListener(
      'keydown',
      (e) => {
        if (scrollingKeys.indexOf(e.keyCode) > -1) {
          e.preventDefault();
        }
      },
      false,
    );
  }, []);

  // scroll when ActiveCell has reached a vertical border
  useEffect(() => {
    const { pos, size, selectedPos, selectedSize } = activeCell;
    const em = parseFloat(getComputedStyle(document.body).fontSize);
    const navbarHeight = 3.8 * em;
    const headerHeight = 1.9 * em;

    const pageTop = navbarHeight + headerHeight + window.scrollY;
    const cellTop = selectedPos ? selectedPos[0] : pos[0];
    const pageBottom = window.innerHeight + window.scrollY;
    const cellBottom = cellTop + (selectedSize ? selectedSize[1] : size[1]);

    const scrollDirection =
      pageBottom - cellBottom - navbarHeight < 0
        ? 1
        : cellTop - pageTop + navbarHeight < 0
        ? -1
        : 0;
    if (scrollDirection) window.scrollBy(0, scrollDirection * size[1]);
  }, [activeCell]);

  return (
    <div
      id="ActiveCell"
      className={`ActiveCell ${activeCell.edit && 'edit'}`}
      style={{
        width: activeCell.size[0],
        height: activeCell.size[1],
        top: activeCell.pos[0],
        left: activeCell.pos[1],
      }}
      onDoubleClick={onDoubleClick}
    >
      {activeCell.edit && (
        <CellInput
          onInputChange={onInputChange}
          inputValue={inputValue}
          validations={
            headers.find((item) => item.key === activeCell.id[1]).validations
          }
        />
      )}
    </div>
  );
};

export default ActiveCell;
