export const bodyFont = 'NunitoSans, Roboto, sans-serif';
export const titlesFont = 'Nunito, Roboto, sans-serif';
export const typeScale = {
    desktop: {
        basePx: '16px',
        xl: '2.6rem',
        lg: '2.2rem',
        md: '1.9rem',
        sm: '1.6rem',
        xs: '1.3rem',
        paragraph: '1rem',
        helperText: '0.9rem',
        copyrightText: '0.8rem',
    },
    mobile: {
        basePx: '14px',
        xxl: '1.9rem',
        xl: '1.75rem',
        lg: '1.6rem',
        md: '1.45rem',
        sm: '1.3rem',
        xs: '1.2rem',
        paragraph: '1rem',
        subParagraph: '0.86rem',
        helperText: '0.8rem',
        copyrightText: '0.75rem',
    },
};
