import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getEntityName } from '@datarips/utils';
import routes from 'shared/constants/routes';
import actions from 'services/actions';
import { Link } from 'react-router-dom';
import {
  getMonthText,
  getDateTextAndTime,
  getCapitalizedString,
} from 'shared/helpers/prettify';
import sheetMessages from 'shared/constants/sheetMessages';

import { Button, Icon, Logo, ProfileButton } from '@datarips/components';
import StyledCustomNavbar from './StyledCustomNavbar';
import {
  getSheetArray,
  getFileName,
  downloadXlsx,
  getArrayFromString,
} from '@datarips/utils';

const CustomNavbar = (props) => {
  const { location, currUser, currDataSheet, tableErrors, dataProps } = props;
  const { errorsList, checkRequired } = tableErrors;
  const [fileContent, setFileContent] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const path = '/' + location.pathname.split('/')[1];
  const dispatch = useDispatch();

  const checkErrors = () => {
    if (errorsList && errorsList.length !== 0) {
      alert(sheetMessages('withErrors', { errorsCount: errorsList.length }));
    } else if (window.confirm(sheetMessages('sendConfirm'))) {
      props
        .sendDataSheet()
        .then(() => {
          alert(sheetMessages('sentSheet'));
        })
        .catch(() => {
          //TODO add catch error to the message
          alert(sheetMessages('sendingError'));
        });
      props.deleteActiveCell();
    }
  };

  const onSend = () => {
    if (!props.currDataSheet.isSent) {
      // props.changeErrorsList([])
      props.removeEmptyRows().then(() => {
        props.readjustErrorsList(); // TODO: is it necessary to run this every time?
        if (!checkRequired) props.checkRequired();
        checkErrors();
      });
    } else {
      alert(sheetMessages('sentSheet'));
    }
  };

  const onDownload = () => {
    let { month, year } = props.currDataSheet;
    let worksheetsList = {
      Planilla: getSheetArray(Object.freeze(props.currDataSheet)),
    };
    const fileName = getFileName({ label: 'Planilla', month, year });
    downloadXlsx(Object.freeze(worksheetsList), fileName);
  };

  const handleFileChange = (evt) => {
    if (evt.target.files) {
      var f = evt.target.files[0];
      if (f) {
        var r = new FileReader();
        r.onload = (e) => {
          var contents = getArrayFromString(
            String(e.target.result).toUpperCase(),
          );
          setFileContent(contents);
        };
        r.readAsBinaryString(f);
      } else {
        console.log('Failed to load file');
      }
    }
  };

  const onUpload = () => {
    setFileLoading(true);
    if (!fileContent) {
      setFileLoading(false);
      return;
    }
    const firstCellId = [0, dataProps[0].key];
    props.setActiveCell(firstCellId);
    dispatch(
      actions.changeCurrSheet({
        action: 'CHANGE_SELECTION',
        value: fileContent,
      }),
    );
    setFileLoading(false);
  };

  return (
    <>
      <StyledCustomNavbar
        fixed={path === routes.EDITOR || path === routes.LOGIN}
        path={path}
      >
        <div className="navbar-left">
          <Link to={routes.HOME}>
            {path === routes.EDITOR && <Icon icon="arrow-left" weight="bold" />}
            <Logo type={path === routes.EDITOR && 'icon'} />
          </Link>

          {path === routes.EDITOR && currDataSheet && (
            <div className="text">
              <h3>
                {currDataSheet.entity
                  ? getEntityName(currDataSheet.entity, currUser)
                  : 'Planilla'}
                : {getMonthText(currDataSheet.month)} {currDataSheet.year}
              </h3>
              <small>
                <strong>Últ. modificación:</strong>{' '}
                {getDateTextAndTime(currDataSheet.updatedAt.toDate())}
              </small>
            </div>
          )}
        </div>

        <div className="navbar-right">
          {path === routes.EDITOR && props.currDataSheet && (
            <div className="buttons">
              {currUser && currUser.isDummy && !props.currDataSheet.isSent && (
                <>
                  <input type="file" onChange={handleFileChange} />
                  <Button
                    isOutlined
                    onClick={onUpload}
                    icon="arrow-up"
                    loading={fileLoading}
                  >
                    Subir
                  </Button>
                </>
              )}
              <Button isOutlined onClick={onDownload} icon="arrow-down">
                Descargar
              </Button>
              <Button
                outlined
                disabled={props.currDataSheet.isSent}
                onClick={onSend}
              >
                {!props.currDataSheet.isSent
                  ? 'Enviar planilla'
                  : 'Planilla enviada'}
              </Button>
            </div>
          )}

          {currUser ? (
            <ProfileButton
              nickname={
                currUser.displayName || getCapitalizedString(currUser.name)
              }
              onLogout={props.logout}
            />
          ) : (
            path !== routes.LOGIN && (
              <Button as={Link} to={routes.LOGIN}>
                Iniciar sesión
              </Button>
            )
          )}
        </div>
      </StyledCustomNavbar>
    </>
  );
};

const mapStateToProps = ({
  currUser,
  currDataSheet,
  dataProps,
  tableErrors,
}) => ({
  currUser,
  currDataSheet,
  dataProps,
  tableErrors,
});
export default connect(mapStateToProps, actions)(CustomNavbar);
