import React from 'react';
import styled from 'styled-components';
import { Logo } from '@datarips/components';

function Loader(props) {
  return (
    <div {...props}>
      <div className="background">
        <div className="circle"></div>
        <div className="image">
          <Logo type="icon" />
        </div>
      </div>
    </div>
  );
}

const StyledLoader = styled(Loader)`
  display: ${(props) => (props.active === false ? 'none' : 'inline-block')};

  .background {
    /* background-color: ${(props) => props.theme.primary.main}; */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin: 0 auto;

    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
  }

  .circle {
    font-size: 8px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 15em;
    height: 15em;
    border-radius: 50%;
    background: ${(props) => props.theme.primary.main};
    background: -moz-linear-gradient(
      left,
      ${(props) => props.theme.primary.main} 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
      left,
      ${(props) => props.theme.primary.main} 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
      left,
      ${(props) => props.theme.primary.main} 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
      left,
      ${(props) => props.theme.primary.main} 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
      to right,
      ${(props) => props.theme.primary.main} 10%,
      rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .circle:before {
    width: 50%;
    height: 50%;
    /* background: #ffffff; */
    background: ${(props) => props.theme.primary.main};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .circle:after {
    /* background: ${(props) => props.theme.primary.main}; */
    background: white;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .image {
    position: absolute;
    top: 50%;
    z-index: 200;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-top: 6px;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    padding: 10px;

    img {
      max-width: 40px;
    }
  }
`;

export default StyledLoader;
