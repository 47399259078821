import React from 'react';

function SelectedCells(props) {
  const { activeCell } = props;

  return (
    <div
      id="SelectedCells"
      className="SelectedCells"
      style={{
        width: activeCell.selectedSize[0],
        height: activeCell.selectedSize[1],
        top: activeCell.selectedPos[0],
        left: activeCell.selectedPos[1],
      }}
    ></div>
  );
}

export default SelectedCells;
