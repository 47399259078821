import styled, { css } from 'styled-components';
const Navbar = styled.div `
  background-color: ${(props) => props.theme.palette.neutral['white']};
  padding: 5px 30px;
  width: 100%;
  max-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 2;

  /* conditional styles */
  ${(props) => props.fixed &&
    css `
      position: fixed;
      top: 0;
      left: 0;
    `}
`;
export default Navbar;
