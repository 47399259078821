const XLSX = require('xlsx');
const { getFullDateString, getInvoiceString } = require('./strings');
const { CSV_HEADERS } = require('../constants');

function getRowArray(item, index, month, year) {
  return CSV_HEADERS.map((header) => {
    const itemValue = item[header.key];

    switch (header.key) {
      case 'day':
        return getFullDateString(itemValue, month, year);
      case 'value':
        return parseInt(itemValue || '0');
      case 'invoice':
        return itemValue || getInvoiceString(month, year, index);
      case 'municipality':
        return String(itemValue).padStart(3, '0');
      default:
        return itemValue || '';
    }
  });
}

function getSheetArray(datasheet) {
  let valuesArray = datasheet.data.map((item, index) => {
    return getRowArray(item, index, datasheet.month, datasheet.year);
  });

  return [CSV_HEADERS.map((header) => header.text), ...valuesArray];
}

// worksheetsList should be an aoa list
// Tip: aoa means Array of Arrays
function downloadXlsx(worksheetsList, fileName) {
  let workbook = XLSX.utils.book_new();

  Object.keys(worksheetsList).forEach((item) => {
    let worksheet = XLSX.utils.aoa_to_sheet(worksheetsList[item]);
    XLSX.utils.book_append_sheet(workbook, worksheet, item);
  });

  XLSX.writeFile(workbook, fileName + '.xlsx', {
    bookType: 'xlsx',
    type: 'array',
  });
}

function getArrayFromString(string) {
  let array = string.split(/\r?\n|\r|\n/g); // 👈 separate by line breaks
  array.shift(); // 👈 remove first row with column names
  array = array.map((item) => item.split(/;|,/g)); // 👈 separate each row by csv separators
  return array;
}

module.exports = { getSheetArray, downloadXlsx, getArrayFromString };
