import React from 'react';
import styled from 'styled-components';
import StyledInput from './StyledInput';
function DatePicker(props) {
    // const { placeholder } = props;
    return React.createElement(StyledInput, { ...props });
}
const StyledDatePicker = styled(DatePicker) `
  input[type='date']& {
    position: relative;
    appearance: none;
    -moz-appearance: none;

    &::-webkit-datetime-edit-text {
      padding: 0 0.3em;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
      cursor: pointer;
    }

    &:after {
      content: '\f718'; // calendar icon bold
      position: absolute;
      top: 14px;
      right: 10px;
      color: ${(props) => props.theme.input.placeholderColor};

      font: normal normal normal 1em/1 'Phosphor';
      color: inherit;
      flex-shrink: 0;
      text-transform: none;
      text-decoration: inherit;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
`;
export default StyledDatePicker;
