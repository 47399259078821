import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import Spinner from './Spinner';
const getButtonColors = ({ theme, ...props }) => {
    const buttonColor = theme.secondary.main;
    const hoverColor = theme.secondary.hover;
    const textColor = theme.secondary.contrast;
    const primary = css `
    color: ${textColor};
    background-color: ${buttonColor};
    &:disabled {
      color: ${theme.palette.neutral[600]};
      background-color: ${theme.palette.neutral[400]};
    }
    &:hover:not(:disabled) {
      /* filter: brightness(110%); */
      background-color: ${hoverColor};
    }
  `;
    const outlined = css `
    color: ${buttonColor};
    background-color: ${textColor};
    border: 1px solid ${buttonColor};
    &:disabled {
      color: ${theme.palette.neutral[500]};
      border-color: ${theme.palette.neutral[500]};
      background-color: ${textColor};
    }
    &:hover:not(:disabled) {
      background-color: ${buttonColor};
      color: ${textColor};
      filter: none;
    }
  `;
    const link = css `
    background-color: transparent;
    color: ${buttonColor};
    text-decoration: underline;
    border: none;
    &:disabled {
      background-color: transparent;
    }
  `;
    return css `
    ${primary}
    ${props.isOutlined && outlined}
    ${props.isLink && link}
  `;
};
const StyledButton = styled.button `
  font-size: 1em;
  font-family: ${(props) => props.theme.fonts.body};
  padding: 0.5em 1.2em;
  outline: none;
  border-radius: 3px;
  text-decoration: none;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative; // This is for the tooltip
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);

  /* colors styles */
  ${(props) => getButtonColors(props)}

  /* conditional styles */
  ${(props) => props.onlyIcon &&
    css `
      min-width: 2.5em;
      padding: 0.5em;
      i {
        margin: 0 auto;
      }
    `}
  ${(props) => (props.disabled || props.isLink) && `box-shadow: none;`}
  ${(props) => props.disabled && `cursor: auto;`}
  ${(props) => props.leftIcon && `flex-direction: row-reverse;`}
`;
function Button(props) {
    const { loading, children, icon, onlyIcon } = props;
    return (React.createElement(StyledButton, { ...props }, loading ? (
    // TODO: Fix color and size of the spinner
    React.createElement(Spinner, { customSize: "1em", type: 3 })) : (React.createElement(React.Fragment, null,
        !onlyIcon && children,
        icon && React.createElement(Icon, { icon: icon })))));
}
export default Button;
