import React from 'react';
import styled from 'styled-components';

// import components
import { Button } from '@datarips/components';

// images
const heroImagePath = '/img/graphics/hero.png';

function Home() {
  return (
    <>
      <Hero>
        <div className="content">
          <h2>¿Necesitas ayuda con tus RIPS?</h2>
          <h1>Prueba nuestro sistema 100% virtual</h1>
          <p>
            Para digitar, preparar y enviar RIPS, SIRHO, estados financieros,{' '}
            entre otros documentos, a las entidades de salud correspondientes.{' '}
            <b>Fácil y sin preocupaciones.</b>
          </p>
        </div>
        <Button className="button" color="crazy">
          Contáctanos ahora
        </Button>
      </Hero>
    </>
  );
}

const Hero = styled.div`
  height: calc(100vh - 13em);
  padding: 5% 10%;

  background-image: url(${heroImagePath});
  background-size: cover;
  background-position: center;
  font-family: 'Nunito';

  & > .content {
    max-width: 45rem;
    margin-bottom: 3rem;
    font-size: 1.7em;

    h1 {
      font-size: 2.3em;
      font-weight: 700;
      line-height: 1.3em;
      margin-top: 0;
    }

    h2 {
      margin-bottom: 0.5em;
    }

    p {
      font-weight: 500;
      line-height: 1.5em;
    }
  }

  & > .button {
    font-family: 'Nunito';
    font-size: 1.3em;
    font-weight: 600;
  }
`;

export default Home;
