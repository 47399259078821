import React, { useState, useEffect } from 'react';

function CellInput(props) {
  const [input, setInput] = useState(null);
  const { inputValue, validations } = props;
  const { type, options } = validations;

  useEffect(() => {
    if (input) input.focus();
  }, [input]);

  const handleInputChange = (e) =>
    props.onInputChange(formattedInput(e.target.value));

  const formattedInput = (inputValue) => inputValue.toUpperCase();

  const inputProps = {
    ref: (input) => setInput(input),
    value: inputValue,
    onChange: handleInputChange,
    type,
    options,
  };

  return <input {...inputProps} />;
}

export default CellInput;

//  TODO: show options from conditional validations
// type === 'select' ?
// <select {...inputProps}>
//   {options &&
//     Object.keys(options).map((item) => (
//       <option key={item} value={item}>{options[item]}</option>
//     ))
//   }
// </select>
// :
