import { keyCodes } from 'shared/constants/keyCodes';

export const keyToMovement = (key) => {
  const parseKeyToMovement = {
    [keyCodes.ARROW_UP]: 'UP',
    [keyCodes.ARROW_DOWN]: 'DOWN',
    [keyCodes.ARROW_LEFT]: 'LEFT',
    [keyCodes.ARROW_RIGHT]: 'RIGHT',
  };
  if (parseKeyToMovement[key]) return parseKeyToMovement[key];
  else return null;
};
