// To get more info about a key, go to:
// https://keycode.info/

export const keyCodes = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  CAPS_LOCK: 20,
  ESC: 27,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  INSERT: 45,
  DEL: 46,
  ALT_GR: 225,

  C: 67,
  S: 83,
  V: 86,
  X: 88,
  Y: 89,
  Z: 90,
};

// ALLOWED KEYS FOR META ACTIONS
export const metaKeys = [keyCodes.CTRL, keyCodes.SHIFT];

// KEYCODES FROM F1 TO F12
export const functionKeys = [...Array(12).keys()].map((x) => x + 112);

// NOT ALLOWED KEYS TO EDIT A CELL
export const nonEditionKeys = [
  ...metaKeys,
  ...functionKeys,
  keyCodes.BACKSPACE,
  keyCodes.TAB,
  keyCodes.ENTER,
  keyCodes.SHIFT,
  keyCodes.CTRL,
  keyCodes.ALT,
  keyCodes.CAPS_LOCK,
  keyCodes.ESC,
  keyCodes.PAGE_UP,
  keyCodes.PAGE_DOWN,
  keyCodes.END,
  keyCodes.HOME,
  keyCodes.ARROW_LEFT,
  keyCodes.ARROW_UP,
  keyCodes.ARROW_RIGHT,
  keyCodes.ARROW_DOWN,
  keyCodes.INSERT,
  keyCodes.DEL,
  keyCodes.ALT_GR,
];

// KEYS THAT TRIGGER PAGE SCROLLING
export const scrollingKeys = [
  keyCodes.ENTER,
  keyCodes.ARROW_UP,
  keyCodes.ARROW_DOWN,
  keyCodes.PAGE_DOWN,
];
