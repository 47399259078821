export const FirebaseConfig = {
  apiKey: 'AIzaSyBlCExMooNfY5-RLQM2d1gU2qYUIzlfn3k',
  authDomain: 'datarips-sheet.firebaseapp.com',
  databaseURL: 'https://datarips-sheet.firebaseio.com',
  projectId: 'datarips-sheet',
  storageBucket: 'datarips-sheet.appspot.com',
  messagingSenderId: '194142050527',
  appId: '1:194142050527:web:10be36952a7d7ebcbcf16a',
  measurementId: 'G-69M7C9JR9M',
};
