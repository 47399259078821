import React from 'react';
import ReactDOM from 'react-dom';
import { DataripsStyleWrapper, defaultTheme } from '@datarips/components';
import App from './App';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from 'services/reducers.js';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <DataripsStyleWrapper theme={defaultTheme}>
      <App />
    </DataripsStyleWrapper>
  </Provider>,
  document.getElementById('root'),
);
