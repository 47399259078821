import styled, { css } from 'styled-components';
import React from 'react';
import Button from '../atoms/Button';
import Modal from '../atoms/Modal';
const Dialog = ({ title, description, image, okButton, cancelButton, onOk, onCancel, children, closeIcon = true, isCentered = true, ...restProps }) => {
    return (React.createElement(Modal, { ...{ ...restProps, closeIcon } },
        React.createElement(Container, { isCentered: isCentered },
            image && React.createElement(Image, { src: image, alt: title }),
            React.createElement("div", { className: "heading" },
                title && React.createElement("h4", null, title),
                description && React.createElement("p", null, description)),
            children,
            React.createElement(Buttons, null,
                cancelButton && (React.createElement(Button, { onClick: onCancel, isOutlined: true }, cancelButton)),
                okButton && React.createElement(Button, { onClick: onOk }, okButton)))));
};
export default Dialog;
const Container = styled.div `
  & > * + * {
    margin-top: 30px;
  }
  h4 {
    margin: 0;
  }

  /* conditional styles */
  ${(props) => props.isCentered &&
    css `
      text-align: center;
    `}
`;
const Buttons = styled.div `
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const Image = styled.img `
  width: 100%;
  max-width: 400px;
`;
