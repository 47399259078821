/* eslint no-eval: 0 */

export const getError = ({
  value,
  validations,
  row,
  conditional_validations,
  checkRequired,
}) => {
  if (validations || conditional_validations) {
    let all_validations = { ...validations };

    if (!value || value.length === 0) {
      if (checkRequired && validations.required) return messages.required;
    } else {
      // each conditional validation has a condition that uses row data
      if (conditional_validations)
        conditional_validations.forEach((item) => {
          if (row && eval(item.if)) {
            all_validations = { ...all_validations, ...item.then };
          } else {
            all_validations = { ...all_validations, ...item.else };
          }
        });

      const { minValue, maxValue, minLength, maxLength, options } =
        all_validations;

      // number value (max and min)
      if (
        validations.type === 'number' &&
        ((minValue && parseInt(value) < minValue) ||
          (maxValue && parseInt(value) > maxValue))
      ) {
        return messages.numValue(minValue, maxValue);
      }

      // length (max and min)
      if (!maxLength && minLength && value.length < minLength) {
        return messages.minLength(minLength);
      }
      if (maxLength && !minLength && value.length > maxLength) {
        return messages.maxLength(maxLength);
      }
      if (
        maxLength &&
        minLength &&
        minLength === maxLength &&
        value.length !== minLength
      ) {
        return messages.exactLength(minLength);
      }
      if (
        maxLength &&
        minLength &&
        (value.length < minLength || value.length > maxLength)
      ) {
        return messages.length(minLength, maxLength);
      }

      // options
      if (options && !Object.keys(options).includes(value)) {
        return messages.options(Object.keys(options).join(', '));
      }
    }
  }

  return null;
};

const messages = {
  required: 'Este campo es obligatorio',
  numValue: (minValue, maxValue) =>
    `Ingrese un valor entre ${minValue} y ${maxValue}`,
  minLength: (minLength) =>
    `La longitud debe ser al menos de ${minLength} caracteres`,
  maxLength: (maxLength) =>
    `La longitud debe ser menor o igual a ${maxLength} caracteres`,
  length: (minLength, maxLength) =>
    `La longitud debe ser entre ${minLength} y ${maxLength} caracteres`,
  exactLength: (length) => `La longitud debe ser de ${length} caracteres`,
  options: (options) => `Elige alguna de las siguientes opciones: ${options}`,
};
