import React from 'react';
import styled from 'styled-components';
const StyledBadge = styled.small `
  font-weight: bold;
  padding: 0 3px;
  border-radius: 3px;
  color: ${(props) => props.theme.textColor.inverted};
  background-color: ${(props) => props.theme.palette.neutral[800]};
`;
function Badge({ text, ...props }) {
    return React.createElement(StyledBadge, { ...props }, text);
}
export default Badge;
