import {
  SET_ACTIVE_CELL,
  DELETE_ACTIVE_CELL,
  SET_INPUT_VALUE,
  SET_META_KEY,
  TOGGLE_EDIT,
  TOGGLE_FOCUS,
  SET_SELECTION,
  COPY_CONTENT,
  CHANGE_ERRORS_LIST,
  CHECK_REQUIRED,
  CLEAR_TABLE_ERRORS,
} from 'services/actionTypes';
import { metaKeys, keyCodes, nonEditionKeys } from 'shared/constants/keyCodes';
import actions from 'services/actions.js';
import { keyToMovement } from 'shared/helpers/keys';

// ACTIVE CELL ACTIONS

export const setActiveCell = (id) => (dispatch, getState) => {
  const { activeCell, currDataSheet, inputValue } = getState();

  // If last cell has been changed
  if (activeCell.id && activeCell.edit) {
    dispatch(
      actions.changeCurrSheet({
        action: 'CHANGE_CELL',
        value: inputValue,
      }),
    );
  }

  if (id && id.length === 2) {
    // Set pos, size and input of new activeCell
    const newInputValue = currDataSheet.data[id[0]][id[1]];
    dispatch({ type: SET_ACTIVE_CELL, id: [parseInt(id[0]), id[1]] });
    dispatch(setInputValue(newInputValue ? newInputValue : ''));
    document.getElementById('data-sheet-component').focus();
  }
};

export const deleteActiveCell = () => (dispatch) => {
  dispatch({ type: DELETE_ACTIVE_CELL });
};

export const replaceActiveCell = () => (dispatch, getState) => {
  const { activeCell } = getState();
  dispatch(clearSelection());
  dispatch(setInputValue(''));
  if (activeCell.edit === false) dispatch(toggleEdit());
};

export const setInputValue = (value) => {
  return { type: SET_INPUT_VALUE, inputValue: value };
};

export const toggleEdit = () => {
  return { type: TOGGLE_EDIT };
};

export const toggleFocus = () => {
  return { type: TOGGLE_FOCUS };
};

const addLastRow = () => actions.changeCurrSheet({ action: 'ADD_LAST_ROW' });

export const removeEmptyRows = () =>
  actions.changeCurrSheet({ action: 'REMOVE_EMPTY_ROWS' });

// TABLE ERRORS

export const changeErrorsList = (errorsList) => (dispatch) => {
  dispatch({ type: CHANGE_ERRORS_LIST, errorsList });
};

export const checkRequired = () => (dispatch) => {
  dispatch({ type: CHECK_REQUIRED });
};

export const clearTableErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_TABLE_ERRORS });
};

export const readjustErrorsList = () => (dispatch, getState) => {
  const { tableErrors, currDataSheet } = getState();
  const removeList = [];

  tableErrors.errorsList.forEach((item) => {
    if (!currDataSheet.data[item[0]]) removeList.push(item);
  });

  for (var i = removeList.length - 1; i >= 0; i--)
    tableErrors.errorsList.splice(removeList[i], 1);
};

// SELECTION ACTIONS

const setSelection = (lastId) => {
  return { type: SET_SELECTION, lastId: [parseInt(lastId[0]), lastId[1]] };
};

const clearSelection = () => (dispatch, getState) => {
  const { activeCell } = getState();
  dispatch(setSelection(activeCell.id));
};

const deleteSelection = () => {
  return actions.changeCurrSheet({ action: 'CHANGE_SELECTION', value: null });
};

// CONTENT ACTIONS

const sliceObj = (obj, a, b, propsKeys) => {
  return Object.entries(propsKeys)
    .slice(propsKeys.indexOf(a), propsKeys.indexOf(b) + 1)
    .map((entry) => obj[entry[1]]);
};

const copyContent = () => (dispatch, getState) => {
  const { activeCell, currDataSheet, dataProps } = getState();
  const { id, lastId } = activeCell;
  const propsKeys = dataProps.map((item) => item.key);
  const [firstIndex, lastIndex] = [
    propsKeys.indexOf(id[1]),
    propsKeys.indexOf(lastId[1]),
  ];

  const [firstRow, lastRow] =
    id[0] < lastId[0] ? [id[0], lastId[0]] : [lastId[0], id[0]];
  const [firstCol, lastCol] =
    firstIndex < lastIndex
      ? [propsKeys[firstIndex], propsKeys[lastIndex]]
      : [propsKeys[lastIndex], propsKeys[firstIndex]];

  const copied = Object.entries(currDataSheet.data)
    .slice(firstRow, lastRow + 1)
    .map((entry) => sliceObj(entry[1], firstCol, lastCol, propsKeys));

  dispatch({ type: COPY_CONTENT, copied });
};

const pasteContent = () => (dispatch, getState) => {
  const { copied } = getState();
  dispatch(
    actions.changeCurrSheet({ action: 'CHANGE_SELECTION', value: copied }),
  );
  dispatch(clearSelection());
};

const cutContent = () => async (dispatch) => {
  dispatch(copyContent());
  dispatch(deleteSelection());
};

// KEY UP AND DOWN

export const handleKeyDown = (e) => (dispatch, getState) => {
  const key = e.keyCode || e.which; // curr key pressed
  const { metaKey } = getState(); // last metaKey pressed

  // Prevent firing focus event when these keys are pressed
  if (key === keyCodes.TAB || key === keyCodes.ENTER) {
    e.preventDefault();
  }

  if (metaKeys.includes(key)) {
    // set metaKey
    dispatch({ type: SET_META_KEY, metaKey: key });
  } else if (metaKey) {
    // handle combination of metakey and key
    dispatch(handleMetaKeyAction(metaKey, key));
  } else {
    // handle another key
    dispatch(handleKeyAction(key));
  }
};

export const handleKeyUp = (e) => async (dispatch) => {
  const key = e.keyCode || e.which; //curr key up

  if (metaKeys.includes(key)) {
    // reset metaKey
    dispatch({ type: SET_META_KEY, metaKey: null });
  }
};

// KEY ACTIONS

const handleKeyAction = (key) => async (dispatch, getState) => {
  const { activeCell, currDataSheet } = getState();

  // In any case
  switch (key) {
    case keyCodes.ENTER:
      if (activeCell.id[0] === currDataSheet.data.length - 1) {
        dispatch(addLastRow());
        // wait for currDataSheet to update with the new row
        setTimeout(() => dispatch(moveActiveCell('DOWN')), 200);
      } else {
        dispatch(moveActiveCell('DOWN'));
      }
      break;
    case keyCodes.TAB:
      dispatch(moveActiveCell('RIGHT'));
      break;
    default:
      break;
  }
  // When user is not focused
  if (activeCell.focus === false) {
    const movement = keyToMovement(key);
    if (movement) dispatch(moveActiveCell(movement));
  }
  // When user is not editing
  if (activeCell.edit === false) {
    switch (key) {
      case keyCodes.DEL:
      case keyCodes.BACKSPACE:
        dispatch(deleteSelection());
        break;
      default:
        if (!nonEditionKeys.includes(key)) {
          dispatch(replaceActiveCell());
        }
        break;
    }
  }
};

// META KEY ACTION

const handleMetaKeyAction = (metaKey, key) => (dispatch, getState) => {
  const { activeCell } = getState();

  // In any case
  if (key === keyCodes.TAB && metaKey === keyCodes.SHIFT) {
    dispatch(moveActiveCell('LEFT'));
  }
  // When user is not editing
  else if (activeCell.edit === false) {
    switch (metaKey) {
      case keyCodes.SHIFT: {
        const movement = keyToMovement(key);
        if (movement) {
          dispatch(moveSelectedCells(movement));
        } else if (!nonEditionKeys.includes(key)) {
          dispatch(replaceActiveCell());
        }
        break;
      }
      case keyCodes.CTRL: {
        switch (key) {
          case keyCodes.C:
            dispatch(copyContent());
            break;
          case keyCodes.V:
            dispatch(pasteContent());
            break;
          case keyCodes.X:
            dispatch(cutContent());
            break;
          // case keyCodes.Z: dispatch(undo()); break;
          // case keyCodes.Y: dispatch(redo()); break;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  }
};

// MOVE ACTIVE CELL

const moveActiveCell = (movement) => (dispatch, getState) => {
  const { activeCell } = getState();
  const newId = dispatch(getMovedId(activeCell.id, movement));
  dispatch(setActiveCell(newId));
};

const moveSelectedCells = (movement) => (dispatch, getState) => {
  const { activeCell } = getState();
  const currCellId = activeCell.lastId ? activeCell.lastId : activeCell.id;
  const lastId = dispatch(getMovedId(currCellId, movement));
  dispatch(setSelection(lastId));
};

const getMovedId = (currCellId, movement) => (dispatch, getState) => {
  const { dataProps, currDataSheet } = getState();
  const propsKeys = dataProps.map((item) => item.key);

  let row = parseInt(currCellId[0]);
  let col = propsKeys.indexOf(currCellId[1]);

  switch (movement) {
    case 'LEFT':
      if (col !== 0) col--;
      break;
    case 'RIGHT':
      if (col !== propsKeys.length - 1) col++;
      break;
    case 'UP':
      if (row !== 0) row--;
      break;
    case 'DOWN':
      if (row !== currDataSheet.data.length - 1) row++;
      break;
    default:
      console.log(movement, 'is a bad choice.');
      console.log('Please use a valid movement.');
      break;
  }

  const newId = [row.toString(), propsKeys[col]];
  return newId;
};
