export default (type, params) => {
  if (!params) params = {};
  return {
    notEditable:
      'Como ya enviaste esta planilla, no puedes editarla 😲. Pero si te faltó algo, puedes comunicarte con el administrador de DataRIPS para que la habilite de nuevo.',

    withErrors: `¡Ups! 😦 tienes ${params.errorsCount} error${
      params.errorsCount > 1 ? 'es' : ''
    }. Por favor soluciónalo${
      params.errorsCount > 1 ? 's' : ''
    } antes de enviar tu planilla.`,

    sendConfirm:
      'Al aceptar, enviarás tu planilla y no podrás seguirla editando. ¿Quieres continuar? 🤔',

    sentSheet:
      'Esta planilla ha sido enviada exitosamente. Pronto te avisaremos por correo sobre tus RIPS 🤗',

    sendingError:
      '!Lo siento! 🙁 No pudimos enviar tu planilla debido a un error interno. Por favor, recarga la página e intenta de nuevo.',
  }[type];
};
