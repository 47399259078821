import styled, { css } from 'styled-components';

const StyledSheet = styled.div`
  ${(props) => css`
    position: relative;
    overflow: visible;
    &:focus {
      outline: none;
    }

    // ---------- TABLE ----------- //

    .Table {
      border-collapse: collapse;
      table-layout: fixed;
      min-width: 100%;
      font-size: 0.9rem;
    }
    th {
      background: ${props.theme.palette.neutral[300]};
      color: ${props.theme.palette.neutral[700]};
      font-weight: 500 !important;
      font: inherit;
      font-size: 0.88em;
      border: 1px solid ${props.theme.palette.neutral[600]} !important;
      z-index: 2;

      &.selected {
        background: ${props.theme.palette.neutral[400]};
      }

      .content {
        display: flex;
        width: 100%;
        justify-content: center;

        i {
          font-size: 1.5em;
          margin-left: 0.5em;
          cursor: pointer;
          &:hover {
            color: ${props.theme.primary.main};
          }
        }
      }
    }
    td,
    th {
      min-width: 3em;
      min-height: 1.9em;
      height: 1.9em;
      max-height: 1.9em;
      padding: 0.2em 0.4em;
      border: 1px solid ${props.theme.palette.neutral[500]};
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-align: left;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    td,
    td input {
      outline: none;
    }
    .ActiveCell,
    td,
    th {
      cursor: cell;
    }

    // ---------- ERROR ----------- //

    td[data-error] {
      position: relative;
      overflow: visible;
      background-color: ${props.theme.status.errorColor.light};

      &:hover:after {
        display: inline;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        margin: -0.3em;
        z-index: 1;

        border-width: 0.3em;
        border-style: solid;
        border-color: ${props.theme.status.errorColor.main} transparent
          transparent transparent;
        transform: rotateZ(-45deg);
      }

      &:after {
        display: none;
        z-index: 2;
        position: absolute;
        content: attr(data-error);
        white-space: pre-wrap;

        width: 7em;
        left: 100%;

        font-size: 0.85rem;
        line-height: 1.4em;
        max-width: 200px;
        border-radius: 3px;
        padding: 0.4em 0.7em;
        background-color: ${props.theme.status.errorColor.light};
        color: ${props.theme.status.errorColor.main};
      }
    }

    // ---------- ACTIVE CELL ----------- //

    .ActiveCell {
      padding: 3px;
      position: absolute;
      border: 2px solid ${props.theme.status.infoColor.main};
      box-sizing: border-box;
      z-index: 1;
    }
    .ActiveCell.edit {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      background-color: white;
    }
    .ActiveCell input,
    .ActiveCell select {
      font: inherit;
      background: none;
      border: none;
      outline: none;
      margin: 0;
      height: 100%;
      width: 100%;
    }

    // ---------- SELECTED CELLS ----------- //

    .SelectedCells {
      position: absolute;
      box-sizing: border-box;
      background-color: rgba(41, 149, 227, 0.1);
      border: 1px solid ${props.theme.status.infoColor.main};
      z-index: 1;
    }
  `}
`;

export default StyledSheet;
