const { MONTHS } = require('../constants');

function getFullDateString(day, month, year) {
	return [
		String(day).padStart(2, '0'),
		String(month + 1).padStart(2, '0'),
		year,
	].join('/');
}

function getInvoiceString(month, year, index) {
	return (
		year + String(month + 1).padStart(2, '0') + String(index).padStart(3, '0')
	);
}

function getFileName({ label, authorName, month, year }) {
	return [label, authorName, MONTHS[month], year]
		.filter((item) => item)
		.join('_');
}

const getStringWithFirstUppercase = (word) =>
	word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

function getCapitalizedString(string) {
	return string
		.split(' ')
		.map((word) => getStringWithFirstUppercase(word))
		.join(' ');
}

module.exports = {
	getFullDateString,
	getInvoiceString,
	getFileName,
	getCapitalizedString,
};
