import { FETCH_CURR_USER, FETCH_CURR_USER_SHEETS } from 'services/actionTypes';

export const currUser = (state = null, { type, currUser, sheets }) => {
  switch (type) {
    case FETCH_CURR_USER: {
      return currUser;
    }
    case FETCH_CURR_USER_SHEETS: {
      return { ...state, sheets };
    }
    default:
      return state;
  }
};
