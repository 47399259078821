import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'services/actions.js';
import sheetMessages from 'shared/constants/sheetMessages';

// Components
import StyledSheet from './StyledSheet';
import Table from './Table';
import ActiveCell from './ActiveCell';
import SelectedCells from './SelectedCells';

function isHeaderVisible(header, currDataSheet) {
  // this might derivate into a more general field like conditionalVisibility
  return (
    !header.isVisibleForEntitySheets ||
    (header.isVisibleForEntitySheets && currDataSheet.entity)
  );
}

function Sheet(props) {
  const { data, activeCell, inputValue, editable, currDataSheet } = props;
  const headers = props.headers.filter((head) =>
    isHeaderVisible(head, currDataSheet),
  );

  useEffect(() => {
    props.clearTableErrors();
    if (editable && data.length) {
      const firstCellId = [0, headers[0].key];
      props.setActiveCell(firstCellId);
    } else {
      props.deleteActiveCell();
    }
  }, []);

  const handleCellClick = (id) => {
    if (editable) {
      props.setActiveCell(id);
    } else {
      alert(sheetMessages('notEditable'));
    }
  };

  const handleHeaderClick = () => {
    // TODO the logic here with params: type, id
  };

  return (
    <StyledSheet
      id="data-sheet-component"
      tabIndex="0"
      className="Sheet"
      onKeyDown={props.handleKeyDown}
      onKeyUp={props.handleKeyUp}
    >
      <Table
        headers={headers}
        data={data}
        selected={activeCell.id}
        onCellClick={handleCellClick}
        onHeaderClick={handleHeaderClick}
        headerFixed
      />

      {activeCell.id && (
        <ActiveCell
          activeCell={activeCell}
          inputValue={inputValue}
          headers={headers}
          onDoubleClick={props.toggleFocus}
          onInputChange={(value) => props.setInputValue(value)}
        />
      )}

      {activeCell.selectedSize && <SelectedCells activeCell={activeCell} />}
    </StyledSheet>
  );
}

const mapStateToProps = ({
  activeCell,
  inputValue,
  currUser,
  currDataSheet,
}) => {
  return { activeCell, inputValue, currUser, currDataSheet };
};
export default connect(mapStateToProps, actions)(Sheet);
