import React from 'react';
export const ICON_WEIGHTS = [
    'thin',
    'light',
    'regular',
    'bold',
    'fill',
    'duotone',
];
function Icon({ icon, weight, size, color, className, ...props }) {
    let iconClassName = `ph-${icon} ph`;
    if (weight && weight !== 'regular') {
        iconClassName += `-${weight}`;
    }
    if (className) {
        iconClassName += ' ' + className;
    }
    const style = {
        color,
        fontSize: size + 'px',
    };
    return React.createElement("i", { ...props, className: iconClassName, style: style });
}
export default Icon;
