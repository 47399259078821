import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../atoms';
import { getCapitalizedString } from '@datarips/utils/helpers';
const getDefaultProps = (props) => ({
    ...props,
    position: props.position || 'right',
    type: props.type || 'default',
});
function ProfileButton({ onLogout, onMenuToggle, ...props }) {
    props = getDefaultProps(props);
    return (React.createElement("div", { ...props, onClick: props.type === 'dropdown' ? onMenuToggle : null },
        !props.isTextHidden && (React.createElement("div", { className: "text" },
            React.createElement("span", { className: "nickname" }, getCapitalizedString(props.nickname)),
            React.createElement("span", null, props.description),
            props.type === 'default' && React.createElement("a", { onClick: onLogout }, "Cerrar Sesi\u00F3n"))),
        React.createElement("div", { className: "image" },
            React.createElement("img", { src: "/img/profile/default.png", alt: "profile" }),
            props.type === 'dropdown' && React.createElement(Icon, { icon: "caret-down", weight: "fill" }))));
}
const StyledProfileButton = styled(ProfileButton).attrs(getDefaultProps) `
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 17px;
  flex-direction: ${(props) => props.position === 'right' ? 'row' : 'row-reverse'};

  .text {
    text-align: ${(props) => props.position};
    & > * {
      color: ${(props) => props.theme.palette.neutral[700]};
      display: block;
    }
    .nickname {
      justify-content: ${(props) => props.position === 'right' ? 'flex-end' : 'flex-start'};
      font-weight: 600;
      color: ${(props) => props.theme.titlesColor.main};
    }
    a {
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.primary.main};
      }
    }
  }

  .image {
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      height: 3em;
      border-radius: 9;
    }
  }

  /* conditional styles */
  ${(props) => props.type === 'dropdown' &&
    css `
      cursor: pointer;
    `}
`;
export default StyledProfileButton;
