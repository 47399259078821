import palette, { emerald, cyan, green, yellow, red, neutral, blue, } from './palettes';
import { bodyFont, titlesFont, typeScale } from './typography';
export const defaultTheme = {
    palette,
    backgroundColor: neutral[200],
    textColor: { main: neutral[900], inverted: neutral['white'] },
    titlesColor: { main: emerald[400], inverted: neutral['white'] },
    primary: {
        main: emerald[300],
        hover: emerald[400],
        contrast: neutral['white'],
    },
    secondary: { main: cyan[300], hover: cyan[400], contrast: neutral['white'] },
    status: {
        warningColor: {
            main: yellow[300],
            light: yellow[200],
            contrast: neutral[800],
        },
        errorColor: {
            main: red[300],
            light: red[200],
            contrast: neutral['white'],
        },
        successColor: {
            main: green[300],
            light: green[200],
            contrast: neutral['white'],
        },
        infoColor: {
            main: blue[300],
            light: blue[200],
            contrast: neutral['white'],
        },
    },
    input: {
        backgroundColor: {
            main: neutral['white'],
            disabled: neutral[400],
            focus: emerald[300],
            contrast: neutral['white'],
        },
        borderColor: {
            main: neutral[600],
            focus: emerald[300],
            disabled: neutral[600],
        },
        placeholderColor: neutral[600],
        messageColor: neutral[700],
    },
    tooltip: {
        backgroundColor: {
            main: neutral[700],
            contrast: neutral['white'],
        },
    },
    fonts: {
        body: bodyFont,
        titles: titlesFont,
    },
    breakpoints: {
        xs: '0',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
    },
    typeScale,
};
// TODO: customize darkTheme (this was taken from momoUI)
export const darkTheme = {
    ...defaultTheme,
    backgroundColor: neutral[800],
    textColor: { main: neutral['white'], inverted: neutral[800] },
    primary: { main: green[300], hover: green[200], contrast: neutral['white'] },
    tertiary: {
        main: neutral[700],
        hover: neutral[600],
        contrast: neutral['white'],
    },
    status: {
        warningColor: {
            ...defaultTheme.status.warningColor,
            main: yellow[200],
            light: yellow[300],
            dark: yellow[100],
        },
        errorColor: {
            ...defaultTheme.status.errorColor,
            main: red[200],
            light: red[300],
            dark: red[100],
        },
        successColor: {
            ...defaultTheme.status.successColor,
            main: green[300],
            light: green[400],
            dark: green[200],
        },
        infoColor: {
            ...defaultTheme.status.successColor,
            main: blue[300],
            light: blue[400],
            dark: blue[200],
        },
    },
    input: {
        ...defaultTheme.input,
        backgroundColor: {
            main: neutral[700],
            disabled: neutral[600],
            focus: neutral['white'],
            contrast: neutral[800],
        },
        borderColor: {
            main: 'transparent',
        },
        placeholderColor: neutral[400],
        focusShadowColor: 'rgba(255, 255, 255, 0.1)',
        messageColor: neutral[200],
    },
};
