import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'services/actions.js';
import { getError } from 'shared/constants/sheetErrors';

// Components
import { Icon } from '@datarips/components';

const Table = (props) => {
  const {
    headers,
    data,
    onCellClick,
    onHeaderClick,
    selected,
    headerFixed,
    tableErrors,
  } = props;
  const { checkRequired, errorsList } = tableErrors;

  useEffect(() => {
    if (headerFixed) {
      document.addEventListener('scroll', () => {
        const translate = 'translate(0,' + window.scrollY + 'px)';
        const selector = document.querySelector('#Table thead');
        if (selector) selector.style.transform = translate;
      });
    }
  }, [headerFixed]);

  const hasError = (id, value, row) => {
    const index = errorsList.indexOf(id.join(','));
    const { validations, conditional_validations } = headers[id[1]]
      ? headers[id[1]]
      : {};

    const error = getError({
      value,
      validations,
      row,
      conditional_validations,
      checkRequired,
    });

    if (error) {
      if (index === -1) errorsList.push(id.join(','));
    } else {
      if (index !== -1) errorsList.splice(index, 1);
    }

    return error;
  };

  return (
    <table id="Table" className="Table">
      <thead>
        <tr>
          <th></th>
          {headers.map((h) => (
            <th
              key={h.key}
              className={selected && selected[1] === h.key ? 'selected' : ''}
              onClick={() => onHeaderClick('col', h.key)}
            >
              <span className="content">
                <span>{h.title.toUpperCase()}</span>
                {h.description && (
                  <div data-tooltip={h.description} data-tooltip-pos="bottom">
                    <Icon icon="question" />
                  </div>
                )}
              </span>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((d, index) => (
          <tr key={index}>
            <th
              className={
                selected && parseInt(selected[0]) === index ? 'selected' : ''
              }
              onClick={() => onHeaderClick('row', index)}
            >
              {index + 1}
            </th>

            {headers.map((h, hIndex) => (
              <td
                id={`${index},${h.key}`}
                key={`${index},${h.key}`}
                role="gridcell"
                data-error={hasError([index, hIndex], d[h.key], d)}
                onClick={() => onCellClick([index, h.key])}
              >
                {d[h.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = ({ tableErrors }) => {
  return { tableErrors };
};
export default connect(mapStateToProps, actions)(Table);
