import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { Card, Icon, Spinner } from '@datarips/components';
import { getMonthText } from 'shared/helpers/prettify';
import routes from 'shared/constants/routes';

export const SHEET_STATES = {
  NotCreated: 'NOT_CREATED',
  Editing: 'EDITING',
  Sent: 'SENT',
  Validated: 'VALIDATED',
};
const STATUS_DETAILS = {
  [SHEET_STATES.NotCreated]: {
    icon: 'plus',
    helperText: 'Clic para activar',
  },
  [SHEET_STATES.Editing]: { icon: 'pencil', helperText: 'Editando' },
  [SHEET_STATES.Sent]: { icon: 'check', helperText: 'Enviado' },
  [SHEET_STATES.Validated]: { icon: 'check', helperText: 'Enviado' },
};

// interface IProps {
//   id?: string;
//   month: number;
//   status?: typeof SHEET_STATES;
//   updatedAt?: string;
//   createdAt?: string;
//   isLoading?: boolean;
// }

function CardWrapper({ status, id, children }) {
  if (status === SHEET_STATES.NotCreated) {
    return <>{children}</>;
  } else {
    return (
      <Link to={`${routes.EDITOR}/${id}`} style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    );
  }
}

const StyledCard = styled(Card)`
  padding: 0;
  position: relative;
  .header {
    display: flex;
    padding: 12px 0;
    border-radius: 5px 5px 0 0;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.status === SHEET_STATES.Editing
        ? props.theme.secondary.main
        : props.status === SHEET_STATES.Sent
        ? props.theme.palette.neutral[400]
        : ''};
  }
  .info {
    padding: 0.65em 1.2em;
    text-align: ${(props) =>
      props.status === SHEET_STATES.NotCreated && 'center'};
    b {
      color: ${(props) => props.theme.textColor.main};
    }
    small {
      color: ${(props) => props.theme.palette.neutral[700]};
      font-weight: 400;
    }
  }
  .spinner {
    margin: 0 auto;
    top: calc(50% - 1.5em - 11px);
    color: ${(props) => props.theme.secondary.main};
    max-width: 5.5em !important;
    max-height: 5.5em !important;
  }
  .loading-fade {
    background: rgb(255 255 255 / 80%);
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

function SheetCard({
  id,
  month,
  status = SHEET_STATES.NotCreated,
  onClick,
  isLoading,
  entity,
  ...props
}) {
  const theme = useContext(ThemeContext);

  return (
    <CardWrapper status={status} id={id}>
      <StyledCard
        status={status}
        isInactive={status === SHEET_STATES.NotCreated}
        onClick={onClick}
        {...props}
      >
        {isLoading && (
          <div className="loading-fade">
            <Spinner className="spinner" type={3} />
          </div>
        )}
        <div className="header">
          <Icon
            icon={STATUS_DETAILS[status] && STATUS_DETAILS[status].icon}
            size={37}
            color={
              status === SHEET_STATES.Editing ? 'white' : theme.secondary.main
            }
            weight={status === SHEET_STATES.Editing ? 'fill' : 'bold'}
          />
        </div>
        <div className="info">
          <b>
            {getMonthText(month)} {entity && `(${entity})`}
          </b>
          <br />
          <small>
            {STATUS_DETAILS[status] && STATUS_DETAILS[status].helperText}
          </small>
        </div>
      </StyledCard>
    </CardWrapper>
  );
}

export default SheetCard;
