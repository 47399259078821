import {
  FETCH_DATAPROPS,
  FETCH_CURR_DATASHEET,
  ADD_FIREBASE_READ,
} from 'services/actionTypes';

export const dataProps = (state = [], { type, dataProps }) => {
  switch (type) {
    case FETCH_DATAPROPS: {
      // Order props by index
      const orderedDataProps = dataProps.sort((a, b) => a.index - b.index);
      return orderedDataProps;
    }
    default:
      return state;
  }
};

export const currDataSheet = (state = null, { type, currDataSheet }) => {
  switch (type) {
    case FETCH_CURR_DATASHEET: {
      return currDataSheet;
    }
    default:
      return state;
  }
};

export const firebaseReadCount = (state = 0, { type }) => {
  switch (type) {
    case ADD_FIREBASE_READ: {
      return state + 1;
    }
    default:
      return state;
  }
};
