import React from 'react';
import styled, { css } from 'styled-components';
const LOGOS_FOLDER_PATH = '/img/logos';
export const LOGO_SIZES = {
    small: '16px',
    medium: '32px',
    large: '48px',
    huge: '64px',
};
const LOGO_TYPES = ['horizontal', 'icon', 'custom'];
const getDynamicAttrs = (props) => ({
    ...props,
    size: props.size || 'medium',
});
const StyledLogo = styled.div.attrs(getDynamicAttrs) `
  display: inline-block;
  height: ${(props) => LOGO_SIZES[props.size]};
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  /* conditional styles */
  ${(props) => props.type === 'custom' &&
    css `
      img {
        background: white;
        padding: 0.25em;
        border-radius: 0.5em;
      }
    `}
`;
function Logo({ color = 'default', size = 'medium', type = 'horizontal', ...props }) {
    if (!LOGO_TYPES.includes(type)) {
        type = 'horizontal';
    }
    const url = type === 'custom' ? props.url : `${LOGOS_FOLDER_PATH}/${type}/${color}.png`;
    return (React.createElement(StyledLogo, { ...{ color, size, type, ...props } },
        React.createElement("img", { src: url, alt: "logo-datarips" })));
}
export default Logo;
