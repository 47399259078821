const MONTHS = require('./months');
const CSV_HEADERS = require('./csvHeaders');
const SPECIALIZATIONS = require('./specializations');

module.exports = {
  MONTHS,
  CSV_HEADERS,
  SPECIALIZATIONS,
  dataripsEmail: 'info@datarips.com',
  dataripsName: 'Ricardo Torres',
  noreplyEmail: 'noreply@datarips.com',
};
