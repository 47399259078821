import styled from 'styled-components';
const StyledInput = styled.input `
  font-size: 1em;
  background-color: ${(props) => props.theme.input.backgroundColor.main};
  font-family: ${(props) => props.theme.fonts.body};
  border: 1px solid ${(props) => props.theme.input.borderColor.main};
  border-radius: 5px;
  padding: 0.6em 0.8em;
  outline: none;
  width: 100%;

  input&,
  textarea& {
    width: calc(100% - 1.6em);
  }

  &:focus {
    border-color: ${(props) => props.theme.input.borderColor.focus};
    filter: drop-shadow(0px 0px 6px rgba(17, 51, 70, 0.25));
  }

  &:disabled {
    background-color: ${(props) => props.theme.input.backgroundColor.disabled};
  }

  ::placeholder {
    color: ${(props) => props.theme.input.placeholderColor};
    opacity: 1;
  }
`;
export default StyledInput;
