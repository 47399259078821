import {
  SET_ACTIVE_CELL,
  DELETE_ACTIVE_CELL,
  SET_INPUT_VALUE,
  TOGGLE_EDIT,
  TOGGLE_FOCUS,
  SET_META_KEY,
  SET_SELECTION,
  COPY_CONTENT,
  CHANGE_ERRORS_LIST,
  CHECK_REQUIRED,
  CLEAR_TABLE_ERRORS,
} from 'services/actionTypes';

const INITIAL_ACTIVE_CELL = { edit: false, focus: false };
export const activeCell = (
  state = INITIAL_ACTIVE_CELL,
  { type, id, lastId },
) => {
  switch (type) {
    case SET_ACTIVE_CELL: {
      const target = document.getElementById(id.join(','));
      const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = target;
      return {
        id,
        lastId: id,
        pos: [offsetTop, offsetLeft],
        size: [offsetWidth, offsetHeight],
        edit: false,
        focus: false,
      };
    }
    case DELETE_ACTIVE_CELL: {
      return INITIAL_ACTIVE_CELL;
    }
    case SET_SELECTION: {
      let [selectedSize, selectedPos] = [null, null];
      if (JSON.stringify(state.id) !== JSON.stringify(lastId)) {
        const target = document.getElementById(lastId.join(','));
        const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = target;
        const [xA, yA, wA, hA] = [
          state.pos[1],
          state.pos[0],
          state.size[0],
          state.size[1],
        ];
        const [xB, yB, wB, hB] = [
          offsetLeft,
          offsetTop,
          offsetWidth,
          offsetHeight,
        ];

        selectedSize = [
          xA < xB ? xB + wB - xA : xA + wA - xB,
          yA < yB ? yB + hB - yA : yA + hA - yB,
        ];
        selectedPos = [yA < yB ? yA : yB, xA < xB ? xA : xB];
      }
      return {
        ...state,
        selectedSize,
        selectedPos,
        lastId,
      };
    }
    case TOGGLE_EDIT: {
      return {
        ...state,
        edit: !state.edit,
        focus: state.edit === false ? false : state.focus,
      };
    }
    case TOGGLE_FOCUS: {
      return {
        ...state,
        edit: true,
        focus: true,
      };
    }
    default:
      return state;
  }
};

export const inputValue = (state = null, { type, inputValue }) => {
  switch (type) {
    case SET_INPUT_VALUE: {
      return inputValue;
    }
    default:
      return state;
  }
};

export const edit = (state = false, { type }) => {
  switch (type) {
    case TOGGLE_EDIT: {
      return !state;
    }
    default:
      return state;
  }
};

export const metaKey = (state = null, { type, metaKey }) => {
  switch (type) {
    case SET_META_KEY: {
      return metaKey;
    }
    default:
      return state;
  }
};

export const copied = (state = null, { type, copied }) => {
  switch (type) {
    case COPY_CONTENT: {
      return copied;
    }
    default:
      return state;
  }
};

const INITIAL_TABLE_ERRORS = { errorsList: [] };
export const tableErrors = (
  state = INITIAL_TABLE_ERRORS,
  { type, errorsList },
) => {
  switch (type) {
    case CHANGE_ERRORS_LIST: {
      return {
        ...state,
        errorsList,
      };
    }
    case CHECK_REQUIRED: {
      return {
        ...state,
        checkRequired: true,
      };
    }
    case CLEAR_TABLE_ERRORS: {
      return INITIAL_TABLE_ERRORS;
    }
    default:
      return state;
  }
};
