import React from 'react';
import styled, { css } from 'styled-components';
import { CARD_SHADOW } from '../utils/commonStyles';
const getDynamicAttrs = ({ isClickable, onClick, padding, ...props }) => ({
    ...props,
    isClickable: isClickable || !!onClick,
    padding: padding || '10px 15px',
});
const StyledCard = styled.div.attrs(getDynamicAttrs) `
  background-color: ${(props) => props.theme.palette.neutral['white']};
  padding: ${(props) => props.padding};
  border-radius: 5px;
  text-decoration: none;
  box-sizing: border-box;

  /* conditional styles */
  ${(props) => props.isClickable && !props.isInactive && CARD_SHADOW}
  ${(props) => props.isInactive &&
    css `
      background-color: transparent;
      border: 1px dashed ${(props) => props.theme.secondary.main};
      border-radius: 5px;
    `}
  ${(props) => props.isClickable &&
    css `
      cursor: pointer;
    `}

  /* hover animation */
  ${(props) => !props.isInactive &&
    props.isClickable &&
    css `
      position: relative;
      top: 0;
      transition: top ease 0.15s;
      &:hover {
        top: -2px;
      }
    `}
`;
function Card({ children, ...props }) {
    return React.createElement(StyledCard, { ...props }, children);
}
export default Card;
